var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
export const loadBrandData = brand => __awaiter(void 0, void 0, void 0, function* () {
  const brandHash = brand => {
    if (!brand) {
      return '';
    }
    const code = Array.from(brand).map(c => c.charCodeAt(0)).join('');
    return "brand-" + code;
  };
  const brandMap = {
    "brand-9797110122": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-9797110122" */"./aanz");
    }),
    "brand-9797114112": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-9797114112" */"./aarp");
    }),
    "brand-979711611497118101108108101114": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-979711611497118101108108101114" */"./aatraveller");
    }),
    "brand-9798114105116101108": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-9798114105116101108" */"./abritel");
    }),
    "brand-979999111114": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-979999111114" */"./accor");
    }),
    "brand-9710511499971109710097": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-9710511499971109710097" */"./aircanada");
    }),
    "brand-97105114110122": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-97105114110122" */"./airnz");
    }),
    "brand-97108971151079797105114": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-97108971151079797105114" */"./alaskaair");
    }),
    "brand-989710410597": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-989710410597" */"./bahia");
    }),
    "brand-989711499101108111": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-989711499101108111" */"./barcelo");
    }),
    "brand-98105108116": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-98105108116" */"./bilt");
    }),
    "brand-98110115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-98110115" */"./bns");
    }),
    "brand-9811111110797989799104": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-9811111110797989799104" */"./bookabach");
    }),
    "brand-98111111107101116121": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-98111111107101116121" */"./bookety");
    }),
    "brand-999711411410111011697108115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-999711411410111011697108115" */"./carrentals");
    }),
    "brand-999711610497121112979910510210599": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-999711610497121112979910510210599" */"./cathaypacific");
    }),
    "brand-9910410197112104111116101108115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-9910410197112104111116101108115" */"./cheaphotels");
    }),
    "brand-9910410197112114111111109115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-9910410197112114111111109115" */"./cheaprooms");
    }),
    "brand-991041019711211610599107101116115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-991041019711211610599107101116115" */"./cheaptickets");
    }),
    "brand-10010110297117108116": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-10010110297117108116" */"./default");
    }),
    "brand-1001011029711710811695111108100": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1001011029711710811695111108100" */"./default_old");
    }),
    "brand-100101102971171081161159549100108": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-100101102971171081161159549100108" */"./defaults_1dl");
    }),
    "brand-10010110811697": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-10010110811697" */"./delta");
    }),
    "brand-10198111111107101114115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-10198111111107101114115" */"./ebookers");
    }),
    "brand-10110011410197109115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-10110011410197109115" */"./edreams");
    }),
    "brand-1011039599111110115111108101": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1011039599111110115111108101" */"./eg_console");
    }),
    "brand-1011039511297114116110101114": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1011039511297114116110101114" */"./eg_partner");
    }),
    "brand-1011031001159510011199115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1011031001159510011199115" */"./egds_docs");
    }),
    "brand-1011031011109910597": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1011031011109910597" */"./egencia");
    }),
    "brand-1011121159510010110297117108116115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1011121159510010110297117108116115" */"./eps_defaults");
    }),
    "brand-1011121159511610110911210897116101": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1011121159511610110911210897116101" */"./eps_template");
    }),
    "brand-101112115951211111171149811497110100": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-101112115951211111171149811497110100" */"./eps_yourbrand");
    }),
    "brand-101115999711210597": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-101115999711210597" */"./escapia");
    }),
    "brand-1011161159511297114116110101114959897115101": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1011161159511297114116110101114959897115101" */"./ets_partner_base");
    }),
    "brand-101116115951129711411611010111495991111101021051039549": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-101116115951129711411611010111495991111101021051039549" */"./ets_partner_config_1");
    }),
    "brand-101116115951129711411611010111495991111101021051039550": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-101116115951129711411611010111495991111101021051039550" */"./ets_partner_config_2");
    }),
    "brand-1011209910110810810111099101103114112": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1011209910110810810111099101103114112" */"./excellencegrp");
    }),
    "brand-10112011210110010597": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-10112011210110010597" */"./expedia");
    }),
    "brand-1011201121011001059795103114111117112": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1011201121011001059795103114111117112" */"./expedia_group");
    }),
    "brand-1011201121011001059795114101102114101115104": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1011201121011001059795114101102114101115104" */"./expedia_refresh");
    }),
    "brand-102101114114105115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-102101114114105115" */"./ferris");
    }),
    "brand-102101119111100105114101107116": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-102101119111100105114101107116" */"./fewodirekt");
    }),
    "brand-102108105103104116115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-102108105103104116115" */"./flights");
    }),
    "brand-104971199710510597110": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-104971199710510597110" */"./hawaiian");
    }),
    "brand-104105108116111110": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-104105108116111110" */"./hilton");
    }),
    "brand-1041111091019711997121": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1041111091019711997121" */"./homeaway");
    }),
    "brand-104111116101108108101114": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-104111116101108108101114" */"./hoteller");
    }),
    "brand-104111116101108115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-104111116101108115" */"./hotels");
    }),
    "brand-104111116101108115116104101991111109910111411611010112011711599111109": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-104111116101108115116104101991111109910111411611010112011711599111109" */"./hotelstheconcertnexuscom");
    }),
    "brand-10411111610110811511610410110411111610110811010112011711599111109": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-10411111610110811511610410110411111610110811010112011711599111109" */"./hotelsthehotelnexuscom");
    }),
    "brand-10411111610110811511610410111410197108112108979910111599111109": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-10411111610110811511610410111410197108112108979910111599111109" */"./hotelstherealplacescom");
    }),
    "brand-104111116119105114101": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-104111116119105114101" */"./hotwire");
    }),
    "brand-1059810111411111511697114": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1059810111411111511697114" */"./iberostar");
    }),
    "brand-10610111611511697114": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-10610111611511697114" */"./jetstar");
    }),
    "brand-10897991111081019999105111110": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-10897991111081019999105111110" */"./lacoleccion");
    }),
    "brand-10897115116109105110117116101": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-10897115116109105110117116101" */"./lastminute");
    }),
    "brand-10997114114105111116116": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-10997114114105111116116" */"./marriott");
    }),
    "brand-109971151161011149997114100": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-109971151161011149997114100" */"./mastercard");
    }),
    "brand-109103109": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-109103109" */"./mgm");
    }),
    "brand-109114106101116": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-109114106101116" */"./mrjet");
    }),
    "brand-111112111100111": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-111112111100111" */"./opodo");
    }),
    "brand-11111498105116122": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-11111498105116122" */"./orbitz");
    }),
    "brand-112971141161101011149910111011611497108": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-112971141161101011149910111011611497108" */"./partnercentral");
    }),
    "brand-11297116116105115111110103114111117112": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-11297116116105115111110103114111117112" */"./pattisongroup");
    }),
    "brand-11210410510810511211210511010197105114108105110101115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-11210410510810511211210511010197105114108105110101115" */"./philippineairlines");
    }),
    "brand-1149899": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1149899" */"./rbc");
    }),
    "brand-114105117": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-114105117" */"./riu");
    }),
    "brand-115971101009811112095116104101109101": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-115971101009811112095116104101109101" */"./sandbox_theme");
    }),
    "brand-11597115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-11597115" */"./sas");
    }),
    "brand-11511697121122": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-11511697121122" */"./stayz");
    }),
    "brand-115119111111112": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-115119111111112" */"./swoop");
    }),
    "brand-1169797112": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1169797112" */"./taap");
    }),
    "brand-116100": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-116100" */"./td");
    }),
    "brand-11611497118101108105991011089711010097105114": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-11611497118101108105991011089711010097105114" */"./travelicelandair");
    }),
    "brand-11611497118101108108105110107": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-11611497118101108108105110107" */"./travellink");
    }),
    "brand-1161149711810110811199105116121": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1161149711810110811199105116121" */"./travelocity");
    }),
    "brand-11611497118101108115111102105": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-11611497118101108115111102105" */"./travelsofi");
    }),
    "brand-116114971181011081199710810997114116112108117115": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-116114971181011081199710810997114116112108117115" */"./travelwalmartplus");
    }),
    "brand-1171001159510010110297117108116": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1171001159510010110297117108116" */"./uds_default");
    }),
    "brand-117110105116101100": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-117110105116101100" */"./united");
    }),
    "brand-1181111219710310111495110101120116": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1181111219710310111495110101120116" */"./voyager_next");
    }),
    "brand-11811498111": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-11811498111" */"./vrbo");
    }),
    "brand-1191011081081151029711410311111611497118101108": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-1191011081081151029711410311111611497118101108" */"./wellsfargotravel");
    }),
    "brand-119111116105102": () => __awaiter(void 0, void 0, void 0, function* () {
      return yield import(/* webpackChunkName: "brand-119111116105102" */"./wotif");
    })
  };
  const brandIndex = brandHash(brand);
  if (brandIndex in brandMap) {
    return yield brandMap[brandIndex]();
  } else {
    return yield brandMap['brand-1171001159510010110297117108116']();
  }
});