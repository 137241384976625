import { FlightsAdvancedFilterType, FlightsTripType, Points, TravelerDetailsType } from '__generated__/api';
import { getLocKeyFromBexApiDateTime } from 'components/multi-item/bex-api-date-time-to-time-loc-key';
export var SortAndFilterOptions;
(function (SortAndFilterOptions) {
  SortAndFilterOptions["SORT"] = "sort";
  SortAndFilterOptions["ACCESSIBILITY"] = "accessibility";
  SortAndFilterOptions["AGENCY_BUSINESS_MODELS"] = "agencyBusinessModels";
  SortAndFilterOptions["AMENITIES"] = "amenities";
  SortAndFilterOptions["BEDROOM_FILTER"] = "bedroomFilter";
  SortAndFilterOptions["BED_TYPE_GROUP"] = "bed_type_group";
  SortAndFilterOptions["CHAIN"] = "chain";
  SortAndFilterOptions["CLEANLINESS"] = "cleaningAndSafetyPractices";
  SortAndFilterOptions["COMMISSION_TIERS"] = "commissionTiers";
  SortAndFilterOptions["DEALS"] = "deals";
  SortAndFilterOptions["HOTEL_BRAND"] = "hotel_brand";
  SortAndFilterOptions["MEAL_PLANS"] = "mealPlan";
  SortAndFilterOptions["REGION_ID"] = "regionId";
  SortAndFilterOptions["PAYMENT_TYPE"] = "paymentType";
  SortAndFilterOptions["POI"] = "poi";
  SortAndFilterOptions["PRICE"] = "price";
  SortAndFilterOptions["PRICE_BUCKETS"] = "priceBuckets";
  SortAndFilterOptions["PROPERTY_NAME"] = "hotelName";
  SortAndFilterOptions["PROPERTY_STYLES"] = "propertyStyles";
  SortAndFilterOptions["REVIEW_SCORE"] = "guestRating";
  SortAndFilterOptions["REWARDS"] = "rewards";
  SortAndFilterOptions["STAR"] = "star";
  SortAndFilterOptions["STAY_OPTIONS_GROUP"] = "stay_options_group";
  SortAndFilterOptions["STAY_TYPE"] = "stayType";
  SortAndFilterOptions["STRUCTURE_TYPE"] = "lodging";
  SortAndFilterOptions["TRAVELER_TYPE"] = "travelerType";
  SortAndFilterOptions["MULTI_NEIGHBORHOOD_GROUP"] = "multi_neighborhood_group";
  SortAndFilterOptions["HIGHLY_RATED_FOR_GROUP"] = "highly_rated_for_group";
  SortAndFilterOptions["AI_SEARCH_QUERY"] = "ai_search_query";
  SortAndFilterOptions["AVAILABILITY_GROUP"] = "availableFilter";
})(SortAndFilterOptions || (SortAndFilterOptions = {}));
export var SearchIntentFields;
(function (SearchIntentFields) {
  SearchIntentFields["SEMDTL"] = "semdtl";
  SearchIntentFields["THEME"] = "theme";
  SearchIntentFields["USER_INTENT"] = "userIntent";
})(SearchIntentFields || (SearchIntentFields = {}));
export var PropertyShopOptions;
(function (PropertyShopOptions) {
  PropertyShopOptions["USE_REWARDS"] = "useRewards";
  PropertyShopOptions["TYPEAHEAD_REGION_TYPE"] = "typeaheadRegionType";
})(PropertyShopOptions || (PropertyShopOptions = {}));
/**
 * Javascript Date objects require a month index which is why we need minus 1
 * TODO: Add date string logic to the BEX API SDK
 */
const getDateStringFromDateRange = dateRange => dateRange && new Date(Date.UTC(dateRange.year, dateRange.month - 1, dateRange.day)).toISOString().split('T')[0];
export const adaptRequestToDict = criteria => {
  var _a, _b;
  const requestFieldsDict = {};
  const dateRange = criteria.primary.dateRange;
  if (dateRange) {
    requestFieldsDict.startDate = getDateStringFromDateRange(dateRange.checkInDate);
    requestFieldsDict.endDate = getDateStringFromDateRange(dateRange.checkOutDate);
  }
  const destination = criteria.primary.destination;
  if (destination.regionId) {
    requestFieldsDict.regionId = destination.regionId;
  }
  if (destination.regionName) {
    requestFieldsDict.destination = destination.regionName;
  }
  const criteriaCounts = (_a = criteria.secondary) === null || _a === void 0 ? void 0 : _a.counts;
  if (criteriaCounts) {
    const typeaheadRegionType = getValueFromCounts(PropertyShopOptions.TYPEAHEAD_REGION_TYPE, criteriaCounts);
    if (typeaheadRegionType) {
      requestFieldsDict.typeaheadRegionType = typeaheadRegionType;
    }
  }
  const criteriaSelections = (_b = criteria.secondary) === null || _b === void 0 ? void 0 : _b.selections;
  if (criteriaSelections) {
    const theme = getValueFromSelections(SearchIntentFields.THEME, criteriaSelections);
    requestFieldsDict.theme = theme ? theme : '';
    const userIntent = getValueFromSelections(SearchIntentFields.USER_INTENT, criteriaSelections);
    requestFieldsDict.userIntent = userIntent ? userIntent : '';
    const semdtl = getValueFromSelections(SearchIntentFields.SEMDTL, criteriaSelections);
    requestFieldsDict.semdtl = semdtl ? semdtl : '';
  }
  return requestFieldsDict;
};
export const adaptRequestSortAndFiltersToDict = criteria => {
  var _a, _b;
  const requestFieldsDict = {};
  const selections = (_a = criteria.secondary) === null || _a === void 0 ? void 0 : _a.selections;
  if (selections) {
    const points = getValueFromSelections(PropertyShopOptions.USE_REWARDS, selections);
    if (points) {
      requestFieldsDict.useRewards = Points.SHOP_WITHOUT_POINTS === points ? 'false' : 'true';
    }
    const sort = getValueFromSelections(SortAndFilterOptions.SORT, selections);
    if (sort) {
      requestFieldsDict.sort = sort;
    }
    const filtersFromSelections = {
      accessibility: getListFromSelections(SortAndFilterOptions.ACCESSIBILITY, selections),
      agencyBusinessModels: getListFromSelections(SortAndFilterOptions.AGENCY_BUSINESS_MODELS, selections),
      amenities: getListFromSelections(SortAndFilterOptions.AMENITIES, selections),
      bedroomFilter: getListFromSelections(SortAndFilterOptions.BEDROOM_FILTER, selections),
      chain: getValueFromSelections(SortAndFilterOptions.CHAIN, selections),
      cleaningAndSafetyPractices: getListFromSelections(SortAndFilterOptions.CLEANLINESS, selections),
      commissionTiers: getListFromSelections(SortAndFilterOptions.COMMISSION_TIERS, selections),
      deals: getListFromSelections(SortAndFilterOptions.DEALS, selections),
      mealPlan: getListFromSelections(SortAndFilterOptions.MEAL_PLANS, selections),
      neighborhood: getValueFromSelections(SortAndFilterOptions.REGION_ID, selections),
      paymentType: getListFromSelections(SortAndFilterOptions.PAYMENT_TYPE, selections),
      poi: getValueFromSelections(SortAndFilterOptions.POI, selections),
      hotelName: getValueFromSelections(SortAndFilterOptions.PROPERTY_NAME, selections),
      propertyStyle: getListFromSelections(SortAndFilterOptions.PROPERTY_STYLES, selections),
      guestRating: getValueFromSelections(SortAndFilterOptions.REVIEW_SCORE, selections),
      rewards: getListFromSelections(SortAndFilterOptions.REWARDS, selections),
      star: getListFromSelections(SortAndFilterOptions.STAR, selections),
      lodging: getListFromSelections(SortAndFilterOptions.STRUCTURE_TYPE, selections),
      travelerType: getListFromSelections(SortAndFilterOptions.TRAVELER_TYPE, selections),
      stayType: getListFromSelections(SortAndFilterOptions.STAY_TYPE, selections),
      // eslint-disable-next-line camelcase
      bed_type_group: getListFromSelections(SortAndFilterOptions.BED_TYPE_GROUP, selections),
      // eslint-disable-next-line camelcase
      stay_options_group: getListFromSelections(SortAndFilterOptions.STAY_OPTIONS_GROUP, selections),
      // eslint-disable-next-line camelcase
      hotel_brand: getListFromSelections(SortAndFilterOptions.HOTEL_BRAND, selections),
      // eslint-disable-next-line camelcase
      multi_neighborhood_group: getListFromSelections(SortAndFilterOptions.MULTI_NEIGHBORHOOD_GROUP, selections),
      // eslint-disable-next-line camelcase
      highly_rated_for_group: getListFromSelections(SortAndFilterOptions.HIGHLY_RATED_FOR_GROUP, selections),
      // eslint-disable-next-line camelcase
      ai_search_query: getListFromSelections(SortAndFilterOptions.AI_SEARCH_QUERY, selections),
      availableFilter: getListFromSelections(SortAndFilterOptions.AVAILABILITY_GROUP, selections)
    };
    Object.keys(filtersFromSelections).forEach(filter => {
      const filterVal = filtersFromSelections[filter];
      if (Array.isArray(filterVal)) {
        if (filterVal.length) {
          requestFieldsDict[filter] = filterVal;
        }
      } else if (filterVal) {
        requestFieldsDict[filter] = filterVal;
      }
    });
  }
  const criteriaRanges = (_b = criteria.secondary) === null || _b === void 0 ? void 0 : _b.ranges;
  if (criteriaRanges) {
    const prices = getValueFromRanges(SortAndFilterOptions.PRICE_BUCKETS, criteriaRanges);
    if (prices.length) {
      requestFieldsDict.price = prices;
    }
  }
  return requestFieldsDict;
};
export const adaptRequestMultiItemFieldsToDict = (multiItemId, bexApiResults = null) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
  const requestFieldsDict = {};
  if (multiItemId) {
    requestFieldsDict.misId = multiItemId;
  }
  if (bexApiResults) {
    const primaryFlight = (_d = (_c = (_b = (_a = bexApiResults.data) === null || _a === void 0 ? void 0 : _a.propertySearch.multiItemSearchContext) === null || _b === void 0 ? void 0 : _b.flights) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.primary;
    if (primaryFlight) {
      requestFieldsDict.origin = (_e = primaryFlight.journeyCriterias[0]) === null || _e === void 0 ? void 0 : _e.origin;
      requestFieldsDict.cabinClass = (_f = primaryFlight.searchPreferences) === null || _f === void 0 ? void 0 : _f.cabinClass;
      requestFieldsDict.tripType = primaryFlight.tripType;
      requestFieldsDict.airlineCode = (_g = primaryFlight.searchPreferences) === null || _g === void 0 ? void 0 : _g.airline;
      requestFieldsDict.directFlights = String((_j = (_h = primaryFlight.searchPreferences) === null || _h === void 0 ? void 0 : _h.advancedFilters) === null || _j === void 0 ? void 0 : _j.includes(FlightsAdvancedFilterType.NONSTOP));
      requestFieldsDict.infantsInSeats = primaryFlight.travelers.filter(traveler => traveler.type === TravelerDetailsType.INFANT_IN_SEAT).length > 0 ? '1' : '0';
      if (primaryFlight.journeyCriterias.length) {
        const dateRange = (_l = (_k = bexApiResults.data) === null || _k === void 0 ? void 0 : _k.propertySearch.criteria) === null || _l === void 0 ? void 0 : _l.primary.dateRange;
        if (dateRange) {
          const {
            checkInDate,
            checkOutDate
          } = dateRange;
          const startDate = getDateStringFromDateRange(checkInDate);
          const endDate = getDateStringFromDateRange(checkOutDate);
          const isOneWay = primaryFlight.tripType === FlightsTripType.ONE_WAY;
          const tripStartDate = getDateStringFromDateRange((_m = primaryFlight.journeyCriterias[0]) === null || _m === void 0 ? void 0 : _m.departureDate);
          const tripEndDate = isOneWay ? endDate : getDateStringFromDateRange((_o = primaryFlight.journeyCriterias[1]) === null || _o === void 0 ? void 0 : _o.departureDate);
          if (startDate !== tripStartDate || tripEndDate !== endDate) {
            requestFieldsDict.partialStay = 'true';
            requestFieldsDict.partialStayStartDate = startDate;
            requestFieldsDict.partialStayEndDate = endDate;
            requestFieldsDict.startDate = tripStartDate;
            requestFieldsDict.endDate = tripEndDate;
          } else {
            requestFieldsDict.partialStay = 'false';
          }
        }
      }
    }
    const primaryCar = (_s = (_r = (_q = (_p = bexApiResults.data) === null || _p === void 0 ? void 0 : _p.propertySearch.multiItemSearchContext) === null || _q === void 0 ? void 0 : _q.cars) === null || _r === void 0 ? void 0 : _r[0]) === null || _s === void 0 ? void 0 : _s.primary;
    if (primaryCar) {
      requestFieldsDict.pickUpTime = getLocKeyFromBexApiDateTime(primaryCar.pickUpDateTime);
      requestFieldsDict.dropOffTime = getLocKeyFromBexApiDateTime(primaryCar.dropOffDateTime);
    }
  }
  return requestFieldsDict;
};
export const getValueFromSelections = (field, selections) => {
  var _a;
  return ((_a = selections.find(selection => selection.id === field)) === null || _a === void 0 ? void 0 : _a.value) || null;
};
export const getListFromSelections = (field, selections) => selections.filter(selection => selection.id === field).map(selection => selection.value);
export const getValueFromRanges = (field, ranges) => {
  const selectedRange = ranges.find(range => range.id === field);
  return selectedRange ? [selectedRange.min.toString(), selectedRange.max.toString()] : [];
};
export const getValueFromCounts = (field, counts) => {
  var _a;
  return ((_a = counts.find(count => count.id === field)) === null || _a === void 0 ? void 0 : _a.value) || null;
};