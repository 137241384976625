/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { comparer, isArrayLike } from 'mobx';
import { ClientLogger } from 'bernie-client';
import { merge } from 'bernie-core';
import { PropertyFilterAmenity } from 'src/common/__generated__/api';
import { SEARCH_ID } from 'src/common/constants/analytics';
import { HOUSE_RULES_GROUP } from 'src/common/constants/filters';
import { STORE_EVENTS } from 'stores/events/store-events';
import { multiItemCarSearchCriteria, multiItemFlightSearchCriteria } from '../../components/multi-item/utils';
import { PackageType } from '../../models/multi-item/multi-item-types';
import { InfositeQueryAdapter } from '../adapters/infosite-query-adapter';
import { SearchQueryAdapter } from '../adapters/search-query-adapter';
import { PropertySort } from './search-criteria-model';
export const URL_PARAM_TO_QUERY_ID_MAPPER = [{
  URL_PARAM_ID: 'packageRates',
  QUERY_BOOLEAN_ID: 'showPackageRates'
}, {
  URL_PARAM_ID: 'vacationRentalsOnly',
  QUERY_BOOLEAN_ID: 'showVacationRentalsOnly'
}, {
  URL_PARAM_ID: 'showFilterNotification',
  QUERY_BOOLEAN_ID: 'showFilterNotification'
}];
export const untrackedQueryParams = ['0', '1', '2', 'authSuccessMsg', 'hotelId', 'lang', 'prefix', 'pwaDialog', 'pwaDialogNested', 'pwaOverlay', 'scrollPosition', 'splat', 'trv_curr', 'trv_dp', 'exp_dp', 'exp_tx', 'trv_bp', 'propertyUnitRoomInfoDialog', SEARCH_ID];
export const singleUseQueryParams = ['rfrr', 'top_dp', 'top_cur', 'trv_dp', 'trv_curr', 'authSuccessMsg', 'exp_dp', 'exp_tx', 'trv_bp'];
export const getLodgingSearchImpactingKeys = () => ['startDate', 'endDate', 'regionId', 'selected', 'latLong', 'destination', 'mapBounds', 'rooms', 'sort', 'hotelName', 'price', 'star', 'lodging', 'amenities', 'travelerType', 'poi', 'useRewards', 'guestRating', 'accessibility', 'paymentType', 'bedType', 'cleaningAndSafetyPractices', 'bedroomFilter', 'deals',
// Deals Filter
'propertyStyle', 'rewards', 'commissionTiers', 'agencyBusinessModels', 'mealPlan', 'neighborhood', 'stayType', 'bedroom_count_gt', 'us_bathroom_count_gt', 'property_type_group', 'location_group', 'accessibility_features_group', 'booking_options_group', 'discounts_group', 'amenities_facilities_group', 'free_cancellation_group', 'house_rules_group', 'nearby_activities_group', 'neighborhood_group', 'premier_host_group', 'properties_good_for_group', 'property_reviews_group', 'rooms_spaces_group', 'safety_group', 'virtual_tour_group', 'nightly_price', 'total_price', 'pricing_group', 'flexibility', 'searchRange', 'flexibleSearchRequirement', 'bed_type_group', 'stay_options_group', 'hotel_brand', 'multi_neighborhood_group', 'highly_rated_for_group', 'categorySearch', 'ai_search_query', 'availableFilter'];
const logger = ClientLogger.getLoggerWithIdentifier('pwa.component.parseData');
export const getMultiItemMutationImpactingKeys = packageType => {
  const keys = ['startDate', 'endDate', 'destination', 'rooms'];
  if (packageType && packageType.includes('F')) {
    keys.push(...multiItemFlightSearchCriteria.filter(key => key !== 'airlineCode'));
  }
  if (packageType && packageType === PackageType.HC) {
    keys.push(...multiItemCarSearchCriteria);
  }
  return keys;
};
const getToday = () => new Date().toISOString().split('T')[0];
const getTomorrow = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow.toISOString().split('T')[0];
};
const parseInfositeValues = (query, l10n, localDateFormat, isVrbo) => {
  var _a;
  const iqaParsedInQuery = InfositeQueryAdapter.parseIn(query);
  const {
    adults,
    children,
    endDate,
    startDate
  } = SearchQueryAdapter.parseOut(iqaParsedInQuery);
  const nullableFields = ['userIntent', 'bedroom_count_gt', 'beaconIssued', 'slots', 'chain', 'daysInFuture', 'children'];
  const emptyField = Object.keys(iqaParsedInQuery).find(key => !nullableFields.includes(key) && !iqaParsedInQuery[key]);
  if (emptyField) logger.logEvent(STORE_EVENTS.QUERY_PARSING_ERROR, `PDP url params parsing error: emptyField=${emptyField}`);
  return Object.assign(Object.assign({}, iqaParsedInQuery), {
    adults,
    children,
    endDate: sanitizeDateToISO(endDate !== null && endDate !== void 0 ? endDate : query.chkout, l10n, localDateFormat, isVrbo),
    rooms: setDefaultRoomsIfNecessary(iqaParsedInQuery.rooms),
    startDate: sanitizeDateToISO(startDate !== null && startDate !== void 0 ? startDate : query.chkin, l10n, localDateFormat, isVrbo),
    petsIncluded: (_a = hasPetsFromQuery(query)) !== null && _a !== void 0 ? _a : false
  });
};
const hasPetsFromQuery = query => {
  var _a, _b;
  return ((_a = query === null || query === void 0 ? void 0 : query.amenities) === null || _a === void 0 ? void 0 : _a.includes(PropertyFilterAmenity.PETS)) || ((_b = query === null || query === void 0 ? void 0 : query.house_rules_group) === null || _b === void 0 ? void 0 : _b.includes(HOUSE_RULES_GROUP.PETS_ALLOWED));
};
const sanitizeDateToISO = (date, l10n, localDateFormat, isVrbo) => {
  const dateObj = l10n.parseLocalDateString(date !== null && date !== void 0 ? date : '', {
    raw: localDateFormat
  }) || l10n.parseLocalDateString(date !== null && date !== void 0 ? date : '', {
    raw: 'yyyy-MM-dd'
  });
  const isoDateString = l10n.formatDate(dateObj, {
    raw: 'yyyy-MM-dd'
  });
  if (!isVrbo && !isoDateString) {
    logger.logEvent(STORE_EVENTS.QUERY_PARSING_ERROR, `url params parsing error: date=${date}`);
  }
  return isoDateString || undefined;
};
const localizeDateFromISO = (date, l10n, localDateFormat) => {
  if (date) {
    const dateObj = l10n.parseLocalDateString(date, {
      raw: 'yyyy-MM-dd'
    });
    const localDate = l10n.formatDate(dateObj, {
      raw: localDateFormat
    });
    if (localDate) {
      return localDate;
    }
  }
  return '';
};
const setDefaultRoomsIfNecessary = rooms => {
  if (!rooms || !isArrayLike(rooms) || rooms.length === 0) {
    return [{
      adults: 2,
      children: []
    }];
  }
  return rooms;
};
function getFilterKeys() {
  return [{
    key: 'sort',
    type: 'string'
  }, {
    key: 'hotelName',
    type: 'string'
  }, {
    key: 'price',
    type: 'array'
  }, {
    key: 'star',
    type: 'array'
  }, {
    key: 'lodging',
    type: 'array'
  }, {
    key: 'amenities',
    type: 'array'
  }, {
    key: 'travelerType',
    type: 'array'
  }, {
    key: 'poi',
    type: 'string'
  }, {
    key: 'guestRating',
    type: 'string'
  }, {
    key: 'accessibility',
    type: 'array'
  }, {
    key: 'paymentType',
    type: 'array'
  }, {
    key: 'bedType',
    type: 'array'
  }, {
    key: 'cleaningAndSafetyPractices',
    type: 'array'
  }, {
    key: 'bedroomFilter',
    type: 'array'
  }, {
    key: 'deals',
    type: 'array'
  }, {
    key: 'propertyStyle',
    type: 'array'
  }, {
    key: 'rewards',
    type: 'array'
  }, {
    key: 'commissionTiers',
    type: 'array'
  }, {
    key: 'agencyBusinessModels',
    type: 'array'
  }, {
    key: 'mealPlan',
    type: 'array'
  }, {
    key: 'paandi',
    type: 'string'
  }, {
    key: 'neighborhood',
    type: 'string'
  }, {
    key: 'stayType',
    type: 'array'
  }, {
    key: 'bedroom_count_gt',
    type: 'string'
  }, {
    key: 'us_bathroom_count_gt',
    type: 'string'
  }, {
    key: 'property_type_group',
    type: 'array'
  }, {
    key: 'location_group',
    type: 'array'
  }, {
    key: 'accessibility_features_group',
    type: 'array'
  }, {
    key: 'booking_options_group',
    type: 'array'
  }, {
    key: 'discounts_group',
    type: 'array'
  }, {
    key: 'amenities_facilities_group',
    type: 'array'
  }, {
    key: 'free_cancellation_group',
    type: 'array'
  }, {
    key: 'house_rules_group',
    type: 'array'
  }, {
    key: 'nearby_activities_group',
    type: 'array'
  }, {
    key: 'neighborhood_group',
    type: 'array'
  }, {
    key: 'premier_host_group',
    type: 'array'
  }, {
    key: 'properties_good_for_group',
    type: 'array'
  }, {
    key: 'property_reviews_group',
    type: 'array'
  }, {
    key: 'rooms_spaces_group',
    type: 'array'
  }, {
    key: 'safety_group',
    type: 'array'
  }, {
    key: 'virtual_tour_group',
    type: 'array'
  }, {
    key: 'nightly_price',
    type: 'array'
  }, {
    key: 'total_price',
    type: 'array'
  }, {
    key: 'pricing_group',
    type: 'string'
  }, {
    key: 'petsIncluded',
    type: 'boolean'
  }, {
    key: 'flexibility',
    type: 'array'
  }, {
    key: 'searchRange',
    type: 'array'
  }, {
    key: 'flexibleSearchRequirement',
    type: 'array'
  }, {
    key: 'bed_type_group',
    type: 'array'
  }, {
    key: 'stay_options_group',
    type: 'array'
  }, {
    key: 'hotel_brand',
    type: 'array'
  }, {
    key: 'multi_neighborhood_group',
    type: 'array'
  }, {
    key: 'highly_rated_for_group',
    type: 'array'
  }, {
    key: 'availableFilter',
    type: 'array'
  }];
}
function getFilterDefaults() {
  return {
    accessibility: [],
    amenities: [],
    guestRating: '',
    hotelName: '',
    lodging: [],
    poi: '',
    price: [],
    sort: PropertySort.RECOMMENDED,
    star: [],
    travelerType: [],
    paymentType: [],
    bedType: [],
    cleaningAndSafetyPractices: [],
    bedroomFilter: [],
    deals: [],
    propertyStyle: [],
    rewards: [],
    commissionTiers: [],
    agencyBusinessModels: [],
    mealPlan: [],
    directFlights: 'false',
    neighborhood: '',
    stayType: [],
    bedroom_count_gt: '',
    us_bathroom_count_gt: '',
    property_type_group: [],
    location_group: [],
    accessibility_features_group: [],
    booking_options_group: [],
    discounts_group: [],
    amenities_facilities_group: [],
    free_cancellation_group: [],
    house_rules_group: [],
    nearby_activities_group: [],
    neighborhood_group: [],
    premier_host_group: [],
    properties_good_for_group: [],
    property_reviews_group: [],
    rooms_spaces_group: [],
    safety_group: [],
    virtual_tour_group: [],
    nightly_price: [],
    total_price: [],
    pricing_group: '',
    petsIncluded: false,
    bed_type_group: [],
    stay_options_group: [],
    hotel_brand: [],
    multi_neighborhood_group: [],
    highly_rated_for_group: [],
    ai_search_query: '',
    availableFilter: []
  };
}
function ensureCorrectTypes(query) {
  const correctedQuery = Object.assign({}, query);
  const filterDefaults = getFilterDefaults();
  // iterate through the filters setting them to a parsed value or their default
  getFilterKeys().forEach(({
    key,
    type
  }) => {
    if (key in correctedQuery) {
      // if it's a string but it's supposed to be an array
      if (type === 'array' && typeof correctedQuery[key] === 'string') {
        // parse based on comma separator
        correctedQuery[key] = correctedQuery[key].split(',').filter(val => val !== '');
      }
      // if it's a array but it's supposed to be an string
      if (type === 'string' && Array.isArray(correctedQuery[key])) {
        correctedQuery[key] = correctedQuery[key].join();
      }
    } else {
      correctedQuery[key] = filterDefaults[key];
    }
  });
  return correctedQuery;
}
function hasSearchCriteriaChanged(prevSearchCriteria, newSearchCriteria, compareKeys) {
  const prevSearchParams = merge.withKeys(compareKeys).merge({}, prevSearchCriteria.toJSON());
  const newSearchParams = merge.withKeys(compareKeys).merge({}, newSearchCriteria.toJSON());
  return !comparer.structural(prevSearchParams, newSearchParams);
}
export const SearchCriteriaUtils = {
  getFilterDefaults,
  ensureCorrectTypes,
  getToday,
  getTomorrow,
  localizeDateFromISO,
  parseInfositeValues,
  sanitizeDateToISO,
  hasSearchCriteriaChanged
};