/* eslint-disable react/display-name,react/prop-types */
import * as React from 'react';
import { CookiesProvider, Cookies } from 'react-cookie';
export default function CookiesPlugin() {
  return {
    name: 'CookiesPlugin',
    onServerWrapRootElement(context, next) {
      const {
        setRootElement
      } = context;
      const cookies = new Cookies(context.request.headers.cookie);
      setRootElement(RootElement => props => React.createElement(CookiesProvider, {
        cookies: cookies
      }, React.createElement(RootElement, null, props.children)));
      return next(context);
    }
  };
}